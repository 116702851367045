:root {
  --green-color: #5e923d;
  --red-color: #e55353;
  --toastify-color-success: var(--green-color);
}

.scrollbar-fixed {
  scrollbar-width: thin;
  scrollbar-color: auto;
  overflow: overlay;
  overflow-y: scroll;
}

.bg-green-login {
  background-color: rgba(94, 146, 61, 0.85);
}

.bg-modal {
  background-color: rgba(240, 240, 240, 0.85);
}

.cursorView {
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

.objectFitCover {
  object-fit: cover;
}

.breakWord {
  word-break: break-all;
}

.one-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-justify {
  text-align: justify;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-italic {
  font-style: italic;
}

.app-logo {
  height: 150px;
  width: auto;
}

.delete {
  display: none;
}

.h-90vh {
  min-height: 90vh !important;
}
.scroll-y {
  max-height: auto !important;
  height: auto !important;
  overflow-y: scroll !important;
}

.h-35 {
  height: 35px !important;
}

.table-responsive {
  height: auto !important;
}

.navigationLoaderLoader {
  z-index: 1;
  padding: 3px 15px;
  background-color: #f9edbe;
  font-weight: 500;
  text-align: center;
  position: absolute;
  top: 12px;
  border: 1px solid orange;
}

.navigationLoaderLoader-2 {
  z-index: 1;
  padding: 3px 15px;
  background-color: #f9edbe;
  font-weight: 500;
  text-align: center;
  position: absolute;
  border: 1px solid orange;
}

.italic {
  font-style: italic;
}

.inputfile2 {
  width: 0.1em;
  height: 0.1em;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.language-item .dropdown-toggle::after {
  display: none;
}

.rotate-90 {
  transform: rotate(90deg);
}

.text-right {
  text-align: right;
}

.off-canvas-body {
}

.dangerouslySetInnerHTML img {
  max-width: 100%;
}

.bar {
  border-left: 5px solid gray;
  border-radius: 4px;
  padding-left: 10px;
}

.bar-success {
  border-color: var(--green-color);
}

.bar-danger {
  border-color: var(--red-color);
}

.inputfile {
  width: 0.1em;
  height: 0.1em;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.chooseAFile {
  cursor: pointer;
  padding: 3px 5px;
  background-color: var(--green-color);
  color: #ffffff;
  border-radius: 5px;
}

.myFile {
  cursor: pointer;
  color: white;
  background-color: #172b4d;
  padding: 5px;
  border-radius: 5px;
  font-size: 0.8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.status-filled,
.status-pending,
.status-not_started {
  font-weight: 500;
  color: gray;
}

.status-accepted,
.status-active,
.status-validated,
.status-done,
.status-true,
.status-ok,
.text-low,
.status-done,
.status-yes {
  font-weight: 500;
  color: var(--cui-primary);
}

.status-paid,
.status-sent,
.status-finished {
  font-weight: 500;
  color: var(--cui-success);
}

.status-rejected,
.status-blocked,
.status-failed,
.text-high,
.status-refused,
.status-false,
.status-aborted,
.status-no {
  font-weight: 500;
  color: var(--cui-danger);
}

.newBadge {
  min-height: 50px;
  margin: 0 0 15px 0;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.4);
  border-radius: 7px;
}

.specialBadge {
  border: 1px solid rgba(128, 128, 128, 0.4);
  border-radius: 7px;
}

.invisible {
  visibility: hidden;
}

.deletePoint {
  background-color: #e55353;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.specialTitle,
.specialTitleChoose {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  cursor: pointer;
  color: black;
}

.specialTitleChoose {
  color: var(--green-color);
}

.tabContent {
  border: 1px solid rgba(0, 0, 21, 0.2);
  border-top: none;
  border-radius: 0 0 7px 7px;
}

.dangerouslySetInnerHTML,
.dangerouslySetInnerHTML img {
  max-width: 100%;
}

.email-editor-loader,
.email-editor-loader-2 {
  z-index: 5;
  position: fixed;
  top: 50px;
  left: 50%;
}

.email-editor-loader-2 {
  left: 30%;
}

#email-editor,
#email-editor-2 {
  z-index: 100;
  position: fixed;
  top: 0;
}

.userImagePreview {
  max-width: 100%;
  max-height: 180px;
}

.imagePreview {
  max-width: 100%;
  max-height: 300px;
}

.rdt_TableRow:hover {
  background: rgba(143, 188, 143, 0.3) !important;
}

.square-li {
  list-style-type: square;
  padding: 0 0 0 25px;
}

.type-normal {
  font-weight: 500;
}

.type-summary {
  font-weight: 500;
  color: darkmagenta;
}

.x-small {
  font-size: 0.75em;
}

@media screen and (min-width: 750px) {
  .new-modal {
    --cui-modal-width: 700px;
  }
}
