// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;
$primary: #5e923d;
$secondary: #c8ced3;
$success: #321fdb;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";
